import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FC, ReactElement, useState } from 'react';

import { FormWrapper } from '../FormWrapper';
import { TUserRegisterData } from '../registrationSchema';
import { PartnerPartnerCategory } from '../../../../types/api';
import { RegistrationSteps } from '../../../../constants/registrationSteps';
import { useTranslation } from 'react-i18next';
import { Stack, TextField } from '@mui/material';

interface IBusinessProps {
  updateRegistrationData: (fields: Partial<TUserRegisterData>) => void;
  title: string;
  moveToStep: (step: RegistrationSteps) => void;
  footer?: ReactElement;
}

export const Business: FC<IBusinessProps> = ({
  updateRegistrationData,
  title,
  moveToStep,
  footer,
}) => {
  const [otherBusinessType, setOtherBusinessType] = useState<string>('');
  const [businessError, setBusinessError] = useState<string>('');
  const [businesssType, setBusinessType] = useState<PartnerPartnerCategory>(
    PartnerPartnerCategory.ECOMMERCE
  );

  const isFormValid = (): boolean => {
    let error = '';
    let isValid = true;
    if (businesssType === PartnerPartnerCategory.OTHER && !otherBusinessType) {
      isValid = false;
      error = t('validation.field-required');
    }
    setBusinessError(error);

    return isValid;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      moveToStep(RegistrationSteps.Websites);
    }
  };

  const { t } = useTranslation();
  return (
    <FormWrapper
      title={title}
      currentStep={1}
      onSubmit={onSubmit}
      //footer={footer}
    >
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
        <Select
          value={businesssType}
          onChange={(e) => {
            const newValue = e.target.value as PartnerPartnerCategory;
            setBusinessType(newValue);
            if (newValue !== PartnerPartnerCategory.OTHER) {
              updateRegistrationData({
                business: newValue,
              });
              if (otherBusinessType !== '') {
                setOtherBusinessType('');
              }
            }
          }}
          size="small"
          sx={{ minWidth: '250px' }}
        >
          <MenuItem value={PartnerPartnerCategory.GASTRO}>
            {t('registration.business-options.gastro')}
          </MenuItem>
          <MenuItem value={PartnerPartnerCategory.ECOMMERCE}>
            {t('registration.business-options.ecommerce')}
          </MenuItem>
          <MenuItem value={PartnerPartnerCategory.PPC}>
            {t('registration.business-options.ppc')}
          </MenuItem>
          <MenuItem value={PartnerPartnerCategory.OTHER}>
            {t('registration.business-options.other')}
          </MenuItem>
        </Select>
        {businesssType === PartnerPartnerCategory.OTHER ? (
          <TextField
            size="small"
            placeholder={
              businessError
                ? businessError
                : t('registration.registration-other-business-type')
            }
            sx={{ minWidth: '250px' }}
            value={otherBusinessType}
            onChange={(e) => {
              setOtherBusinessType(e.target.value);
              updateRegistrationData({
                business: e.target.value as string,
              });
            }}
            error={!!businessError}
          />
        ) : null}
      </Stack>
    </FormWrapper>
  );
};
