import { useState, useEffect, FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useLoading } from '../../../context/loading-context';
import {
  TCustomError,
  useErrorHandler,
} from '../../../context/error-handler-context';
import axios from '../../../utils/axios';

import styles from './Application.module.scss';
import { ActiveApplicationDto, BankDto } from '../../../types/api';
import { useAppSelector } from '../../../selectors/selectors';
import IbanSubmission from '../../Common/IbanSubmission';

interface IApplicationBankProps {
  handleBankSubmit: (data: { iban: string }) => void;
  applicationData: ActiveApplicationDto;
}

const ApplicationBank: FC<IApplicationBankProps> = ({
  handleBankSubmit,
  applicationData,
}) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const { currentCustomer, defaultCurrency } = useAppSelector(
    (state) => state.currentCustomer
  );

  const [banks, setBanks] = useState<Array<BankDto>>([]);

  const applicationCurrency =
    applicationData?.product?.currency || defaultCurrency;

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        setLoading(true);
        const response = await axios.get<Array<BankDto>>(
          `banks/${currentCustomer?.country}`
        );
        setBanks(response.data.filter((b) => b.active));
      } catch (err) {
        setError(err as TCustomError);
      } finally {
        setLoading(false);
      }
    };

    fetchBanks();
  }, []);

  return (
    <>
      <div className="gradient info">
        <h1>
          <span>{t('application-bank-title')}</span>
        </h1>
      </div>

      <div className={styles['body']}>
        <IbanSubmission
          banks={banks}
          title={
            <Trans
              i18nKey="application-bank-which-acc"
              components={{ br: <br />, strong: <strong /> }}
            />
          }
          currency={applicationCurrency}
          handleBankSubmit={handleBankSubmit}
          setError={setError}
        />
      </div>
    </>
  );
};

export default ApplicationBank;
