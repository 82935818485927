import { useState, FormEvent, FC } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import TextEdit from '../../UI/TextEdit';
import Button from '../../UI/Button';

import styles from './Application.module.scss';
import {
  ActiveApplicationDto,
  ApplicationRevenues,
  IsoCurrencyCode,
} from '../../../types/api';

interface IApplicationRevenueProps {
  handleRevenueSubmit: (data: ApplicationRevenues) => void;
  revenues: ApplicationRevenues;
  applicationData: ActiveApplicationDto;
}

const ApplicationRevenue: FC<IApplicationRevenueProps> = ({
  handleRevenueSubmit,
  revenues,
  applicationData,
}) => {
  const { t } = useTranslation();

  const currency = applicationData.product?.currency as IsoCurrencyCode;

  const [revenue, setRevenue] = useState<ApplicationRevenues>({
    revenues3MonthAvg: revenues?.revenues3MonthAvg,
    expenses3MonthAvg: revenues?.expenses3MonthAvg,
    repaymentsMonthAvg: revenues?.repaymentsMonthAvg,
    revenuesYear: revenues?.revenuesYear,
    expensesYear: revenues?.expensesYear,
  });

  const submitHandler = (event: FormEvent) => {
    event.preventDefault();
    handleRevenueSubmit(revenue);
  };

  const handleInputChange = (
    field: keyof ApplicationRevenues,
    value: string
  ) => {
    setRevenue((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div>
      <div className="gradient info">
        <h1>
          <span>{t('application-revenue-title')}</span>
        </h1>
      </div>

      <div className={styles['body']}>
        <p>
          <Trans
            i18nKey="application-revenue-txt"
            components={[<strong></strong>]}
          ></Trans>
        </p>

        <form onSubmit={submitHandler}>
          <div className={styles['form-wrapper']}>
            <TextEdit
              autoFocus={true}
              id="revenues3MonthAvg"
              label={t('application-revenue-revenue-3-month-avg')}
              value={String(revenue.revenues3MonthAvg)}
              type="number"
              required
              currency={currency}
              onChange={(value: string) => {
                handleInputChange('revenues3MonthAvg', value);
              }}
              fullWidth
            />
            <TextEdit
              id="expenses3MonthAvg"
              label={t('application-revenue-expenses-3-month-avg')}
              value={String(revenue.expenses3MonthAvg)}
              type="number"
              required
              currency={currency}
              onChange={(value: string) => {
                handleInputChange('expenses3MonthAvg', value);
              }}
              fullWidth
            />
            <TextEdit
              id="repaymentsMonthAvg"
              label={t('application-revenue-repayments-month-avg')}
              value={String(revenue.repaymentsMonthAvg)}
              type="number"
              required
              currency={currency}
              onChange={(value: string) => {
                handleInputChange('repaymentsMonthAvg', value);
              }}
              fullWidth
            />
            <TextEdit
              id="revenuesYear"
              label={t('application-revenue-revenues-year')}
              value={String(revenue.revenuesYear)}
              type="number"
              required
              currency={currency}
              onChange={(value: string) => {
                handleInputChange('revenuesYear', value);
              }}
              fullWidth
            />
            <TextEdit
              id="expensesYear"
              label={t('application-revenue-expenses-year')}
              value={String(revenue.expensesYear)}
              type="number"
              required
              currency={currency}
              onChange={(value: string) => {
                handleInputChange('expensesYear', value);
              }}
              fullWidth
            />
          </div>

          <div className={styles['form-wrapper']}>
            <div className={styles.button}>
              <Button
                type="submit"
                className="application"
                id="app_declared_income"
              >
                {t('application-button-continue')}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ApplicationRevenue;
